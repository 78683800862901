import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { login } from '../../actions/authActions';
import { connect } from 'react-redux'; 
import LoadingSpinner from '../layout/LoadingSpinner'; 



class Login extends Component{
    state = { isLoading: false }
    
    handleSubmit = async (event) => {
        event.preventDefault(); 
        let credentials = {
            email: event.target.email.value,
            password: event.target.password.value
        }
        this.setLoading(true); 
        let success = await login(credentials); 
        if(!success) this.setLoading(false); 
    }

    setLoading = (loading) => this.setState({isLoading:loading});
    
    render(){
        let isAuthenticated = this.props.auth.isAuthenticated; 
        if(isAuthenticated){
            window.location.href = '/'; 
        }
        let buttonText = this.state.isLoading ? <LoadingSpinner/> : "Login"; 
        return (
            <Container>
                <Form  onSubmit = {this.handleSubmit}>
                    <Form.Control   onChange={this.handleChange} id = "email" placeholder = "Email Address"/> 
                    <Form.Control type = 'password' id = "password" placeholder = "Password"/> 
                    <Button 
                        name='login' 
                        variant = "primary" 
                        type = 'submit'
                        disabled={this.state.isLoading}
                    >
                            {buttonText} 
                    </Button>
                </Form>
            </Container>
        )
    }
}


const mapStateToProps = state => ({
    auth: state.auth,
    isError:state.error.isError
});

export default connect(mapStateToProps)(Login); 