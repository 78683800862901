import axios from 'axios';
import store from '../store'; 
import { SET_CURRENT_USER } from './types';
import jwt_decode from 'jwt-decode'; 
import { configureAxios, axiosLogout } from '../middleware/configureAxios';
import { setError } from './errorActions';
import { selectUser } from './adminActions';  
import { isEmpty } from '../functions/helperFunctions';

export const setCurrentUser = (user) => {
    /** 
    *   Sets an authorized users information
    *   Into application state
    * 
    *   @param {JSON} user 
    */
    store.dispatch({
        type:SET_CURRENT_USER,
        payload:user
    })
}

export const registerUser = async (userData) => {
    /**  
    *   Attempt to register a new user
    * 
    *   @param {JSON} userData
    */
    try{
        let resp = await axios.post('register',userData);
        if(resp) return true;
        return false; 
    }
    catch(err) {
        console.log(err); 
        setError('REGISTER USER ERROR'); // previously Network ERROR
    }
}

export const checkToken = () => {
    /** 
    *   Checks local storage for a  valid jwt
    *   If found saves users information into 
    *   application state && http client
    *   else configure http client for
    *   un- authenticated requests 
    * 
    *   @return {boolean}
    */
    console.log('Checking for token...'); 
    if(localStorage.jwtToken){
        const decoded = jwt_decode(localStorage.jwtToken);
        const currentTime = Date.now()/1000;
        if (decoded.exp < currentTime){
            logoutUser(); 
            window.location.href = '/';
            return false; 
        }
        else{
            if(decoded.is_admin && !isEmpty(decoded.selected_user)){
                selectUser(decoded.selected_user);
            }
            setCurrentUser(decoded); 
        }   
    }
    configureAxios(); 
}

export const login = async (userLoginData) => {
    /** 
    *   Attempt to log in user
    * 
    *   @param {JSON} userLoginData
    *   @return {boolean}
    */
    try{
        let response = await axios.post('login',userLoginData);
        console.log(response); 
        if(!response){
            return false;  
        }
        return true; 
    }
    catch(err){
        console.log(err); 
        setError('USER LOGIN DATA ERROR'); // previously Network Error
    }
}

export const logoutUser = () => {
    /**
     *  Log out a user and remove
     *  any user information from 
     *  app state and local storage
     */
    localStorage.removeItem('jwtToken');
    axiosLogout(); 
    setCurrentUser(''); 
    window.location.href = '/'; 
}

export const resetPassword = async (selectedUser, newPassword) => {
    const postData = {
        selectedUser: selectedUser,
        newPassword: newPassword
    }
    let response = await axios.post('/resetPassword',postData); 
    if (isEmpty(response.ERROR)){
        return true;
    }
    return false; 
}
