import React, { Component } from 'react';
import ReactFileReader from 'react-file-reader';
import { resetJob, setAudioFiles } from '../../actions/createJobActions';
import { connect } from 'react-redux';
import { isEmpty } from '../../functions/helperFunctions';
import Button from 'react-bootstrap/Button';
// import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import AudioReactRecorder, { RecordState } from './AudioReactRecorder'
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal'

// var file = null;
let selected_devices = []; // the device ids of the devices the user selected
let selected_device_names = []; // the names (human readable) of the devices the user selected
let numRecorded = [];
let audioDataArr = [];
let i = 0;

function selectedDevices() {
    let children = document.getElementById("devicelist").childNodes;
    // console.log("Selected audio input devices:");
    for (var x = 0; x < children.length; x++) { // for each child element of the devicelist element
        if (children[x].tagName === 'INPUT' && children[x].type === 'checkbox' && children[x].checked === true) { // if the device is an input device that has been selected by the user
            // console.log("\t", children[x]); // here for debugging/testing purposes
            if (!selected_devices.includes(children[x])) { // if the device has not already been selected before
                selected_devices.push(children[x].id); // add the device id to an array
                selected_device_names.push(children[x].name); // add the device name/label to an array
            }
        }
    }
    if (selected_devices.length === 0 || selected_devices.length > 8) { // if the amount of selected devices is outside of the acceptable range
        return;
    }
    // console.log(selected_devices);
    var recorders = document.getElementById("recorderElements");
    // console.log("recorders:", recorders);
    // console.log("selected_devices:", selected_devices, "selected_devices.length:", selected_devices.length);
    for (var i = 0; i < selected_devices.length; i++) {
        recorders.children[i].firstChild.device_id = selected_devices[i]; // change the device id of the AudioReactRecorder element to the device id chosen by the user

        // console.log(recorders.children[i]);
        recorders.children[i].style.display = "block"; // change the display property of the div enclosing the AudioReactRecorder element from "none" to "block"
        // console.log("recorders.children[" + i + "]:", recorders.children[i]);
    }
    for (var i = recorders.children.length - 1; i >= selected_devices.length; i--) {
        recorders.children[i].style.display = "none";
        recorders.removeChild(recorders.lastChild);
    }
    document.getElementById("devicelist").style.display = 'none';
    document.getElementById("underForm").style.display = 'block';
    // console.log("recorders: ", recorders);
}

/* Populate the "Select Audio Devices" form with audio input devices */
function populateForm() {
    navigator.mediaDevices.enumerateDevices().then(function (devices) {
        document.getElementById("devicelist").innerHTML = "<h3>Choose Up to Eight Audio Input Devices</h3><hr/>";
        for (let i = 0; i < devices.length; i++) { // for each device
            if (devices[i].kind === 'audioinput') { // only if it is an audio input device
                // console.log("\tkind =", devices[i].kind, "label =", devices[i].label, "id =", devices[i].deviceId); // here for debugging purposes

                var inputEl = document.createElement("input"); // create an HTML input element
                inputEl.type = "checkbox"; // of type checkbox
                inputEl.id = devices[i].deviceId; // with an id of the device's id
                inputEl.name = devices[i].label;

                var labelEl = document.createElement("label"); // create a label for the input element
                labelEl.for = devices[i].label; // set which device the label is for
                labelEl.innerHTML = "&nbsp;" + devices[i].label + "&#013;"; // set the text of the label

                document.getElementById('devicelist').appendChild(inputEl); // add the input element to the form
                document.getElementById('devicelist').appendChild(labelEl); // add the label element to the form
                document.getElementById('devicelist').innerHTML += "<br/>"; // add a line break after the new elements
            }
        }
        var submitBtn = document.createElement("button"); // create a submit button for the user to click upon selecting the devices
        submitBtn.id = 'submitdevicechoices';
        submitBtn.type = 'button';
        submitBtn.onclick = selectedDevices; // call the selectedDevices function when the button is clicked. NOTE: there are not parentheses after selectedDevices because if we add them the function will be called when the page loads
        submitBtn.innerHTML = "Submit Device Choices";
        document.getElementById('devicelist').appendChild(submitBtn); // add the submit button to the form
        let children = document.getElementById("devicelist").childNodes;
        for (var x = 0; x < children.length; x++) {
            if (children[x].tagName === 'INPUT' && children[x].type === 'checkbox' && children[x].checked === true) { // if the device is an input device that has been selected by the user
                submitBtn.disabled = false;
            }
        }
    });
}


class SelectFile extends Component {

    constructor(props) {
        super(props)

        this.num_devices = {}

        this.state = {
            recordState: null,
            show: false
        }
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }


    handleFiles = (files) => {
        document.getElementById("RecordButton").style.display = "none";
        let fixedFiles = { ...this.props.files };
        Object.keys(files).forEach(key => {
            let newKey = files[key].name;
            fixedFiles[newKey] = files[key];
        })
        setAudioFiles(fixedFiles);
        // console.log("fixed", fixedFiles)
    }

    addRecordArray = (audioData) => {
        // console.log(selected_devices.length);
        i++;
        if (i <= selected_devices.length) {
            document.getElementById("recordStop").style.display = "none";
            document.getElementById("recordStart").style.display = "none";
            document.getElementById("download").style.display = "block";
            // document.getElementById("download").href += audioData.url;
            audioDataArr.push(audioData.url);
            let f = new File([audioData.blob], [selected_device_names[i - 1] + ".wav"], { lastModified: new Date().getTime(), type: "audio/wav" });
            numRecorded.push(f);
            this.handleFiles(numRecorded);
        }
    }

    downloadFiles = () => {
        for (var i = 0; i < audioDataArr.length; i++) {
            let downloadUrl = audioDataArr[i];
            const anchor = document.createElement("a");
            anchor.href = downloadUrl;
            anchor.download = selected_device_names[i] + ".wav";

            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        }
    }

    start = () => {

        this.setState({
            recordState: RecordState.START
        })
    }

    stop = () => {
        this.setState({
            recordState: RecordState.STOP
        })
    }

    changeDis = () => {
        document.getElementById("RecordButton").style.display = "none";
        document.getElementById("RecordingTools").style.display = "block";
        document.getElementById("selectFileBtn").style.display = "none";
    }
    showModal = () => {
        this.setState({ show: true });
    }

    hideModal = () => {
        this.setState({ show: false });
    }

    render() {
        const { recordState } = this.state;
        const formStyle = {
            border: '1px solid',
            padding: '5px',
            backgroundColor: 'rgb(242,242,242)'
        };

        let recordStyle = {
            width: "100%",
            display: "none",
            textAlign: "center"
        }

        let recordBtnStyle = {
            width: "100%",
            display: "block",
            textAlign: "center"
        }
        let cancelText = "Cancel Job";
        let hasFiles = !isEmpty(this.props.files);
        if (hasFiles === true) {
            document.getElementById("Breaks").style.display = "none";
        }
        let buttonText = hasFiles ? "Add files to transcription job" : "Select Files to Upload";
        let recordText = "Record your own";
        let popHeader = "Are you sure you want to cancel the job?";
        return (
            <div>
                <div id="selectFileBtn" style={this.props.style}>
                    <ReactFileReader fileTypes='audio/*' multipleFiles={true} handleFiles={this.handleFiles}>
                        <Button>{buttonText}</Button>
                    </ReactFileReader>
                </div>
                <div id="RecordButton" style={recordBtnStyle}>
                    <Button onClick={this.changeDis}>{recordText}</Button>
                </div>
                <div id="RecordingTools" style={recordStyle}>
                    <Form id='devicelist' style={formStyle} onLoad={populateForm()} ></Form>

                    <div id="underForm" style = {{display: "none"}}>

                        <Button id="recordStart" onClick={this.start}>Start</Button>

                        <Button id="recordStop" onClick={this.stop}>Stop</Button>

                        <div id="recorderElements" style={{ border: '1px solid', backgroundColor: "rgb(242,242,242)" }}>
                            <div style={{ display: 'none' }}>
                                <AudioReactRecorder backgroundColor={'rgb(242,242,242)'} state={recordState} onStop={this.addRecordArray} canvasWidth={'1075px'} device_id={selected_devices[0]} />
                            </div>
                            <div style={{ display: 'none' }}>
                                <AudioReactRecorder backgroundColor={'rgb(242,242,242)'} state={recordState} onStop={this.addRecordArray} canvasWidth={'1075px'} device_id={selected_devices[1]} />
                            </div>
                            <div style={{ display: 'none' }}>
                                <AudioReactRecorder backgroundColor={'rgb(242,242,242)'} state={recordState} onStop={this.addRecordArray} canvasWidth={'1075px'} device_id={selected_devices[2]} />
                            </div>
                            <div style={{ display: 'none' }}>
                                <AudioReactRecorder backgroundColor={'rgb(242,242,242)'} state={recordState} onStop={this.addRecordArray} canvasWidth={'1075px'} device_id={selected_devices[3]} />
                            </div>
                            <div style={{ display: 'none' }}>
                                <AudioReactRecorder backgroundColor={'rgb(242,242,242)'} state={recordState} onStop={this.addRecordArray} canvasWidth={'1075px'} device_id={selected_devices[4]} />
                            </div>
                            <div style={{ display: 'none' }}>
                                <AudioReactRecorder backgroundColor={'rgb(242,242,242)'} state={recordState} onStop={this.addRecordArray} canvasWidth={'1075px'} device_id={selected_devices[5]} />
                            </div>
                            <div style={{ display: 'none' }}>
                                <AudioReactRecorder backgroundColor={'rgb(242,242,242)'} state={recordState} onStop={this.addRecordArray} canvasWidth={'1075px'} device_id={selected_devices[6]} />
                            </div>
                            <div style={{ display: 'none' }}>
                                <AudioReactRecorder backgroundColor={'rgb(242,242,242)'} state={recordState} onStop={this.addRecordArray} canvasWidth={'1075px'} device_id={selected_devices[7]} />
                            </div>
                        </div>
                    </div>
                    <div id={"download"} style={{ display: "none" }}>
                        <Button onClick={this.downloadFiles}>Download Audio File(s)</Button>
                    </div>
                </div>
                <div style={this.props.style}>
                    <Button onClick={this.showModal}>{cancelText}</Button>

                    <Modal
                        show={this.state.show}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header >
                            <Modal.Title id="contained-modal-title-vcenter">
                                {popHeader}
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Footer>
                            <Button onClick={resetJob}>Confirm</Button>
                            <Button onClick={this.hideModal}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <div id="Breaks">
                    <br /><br /><br /><br /><br />
                </div>

            </div>

        )
    }
}

// wire up state store 
const mapStateToProps = state => ({
    files: state.createJob.files,
    audioData: state.createJob.files
})


export default connect(mapStateToProps)(SelectFile); 