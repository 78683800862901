import React, { Component } from 'react'; 
import {  confirmJob } from '../../actions/createJobActions';
import { connect } from 'react-redux';  
import { isEmpty } from '../../functions/helperFunctions'; 
import Button from 'react-bootstrap/Button';
import LoadingSpinner from '../layout/LoadingSpinner';



class UploadFiles extends Component{
    handleButton = () => {
        confirmJob(true);  
    }
    
    render(){
        let disableButton = isEmpty(this.props.files) || isEmpty(this.props.jobName)|| this.props.isUploading; 
        let buttonText = this.props.isUploading ?   <LoadingSpinner/> : 'Upload File(s)'
        
        return(
            <div style={this.props.style}>
                <Button disabled={disableButton} onClick={this.handleButton}> 
                    {buttonText}
                </Button>
            </div>
            ); 
    }
}


const mapStateToProps = state => ({
    user:state.auth.user.email,
    files:state.createJob.files,
    jobName:state.createJob.jobName,
    isUploading:state.createJob.isUploading,
    confirmation:state.createJob.confirmation
})

export default connect (mapStateToProps)(UploadFiles); 