import axios from 'axios'; 
import store from '../store'; 
import { SET_USER_INFO, SELECT_USER } from '../actions/types'; 
import { isEmpty } from '../functions/helperFunctions';

/*
*   Only admin has access to the functions in this file
*/ 

export const selectUser = (email) => {
    /**
    *   IF an admin selects another user the
    *   users email address is saved in the
    *   application state 
    * 
    *   @param {string} email 
    */
    store.dispatch({
        type:SELECT_USER,
        payload:email
    })
}
export const adminSelectedUser = (postData) => {
    /** 
    *   Checks application state to see if an admin has
    *   Requested to view information for another user
    *   If Yes it is added to the data for the http 
    *   request
    * 
    *   @param {JSON} postData
    *   @return {JSON} 
    */
    if(!isEmpty(postData.selectedUser)){
        return postData;
    }
    let selectedUser = store.getState().admin.selectedUser;
    if(selectedUser){
        postData['selectedUser'] = selectedUser;
    }
    return postData; 
}


export const setUserInfo = async () => {
    /** 
    *   Saves all registered user's information 
    *   into application state
    */
    let response = await axios.post('getUserInfo'); 
    let userInfo = response.data.user_info;
    store.dispatch({
        type:SET_USER_INFO,
        payload:userInfo
    })
}



