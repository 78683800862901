
import { SET_USER_INFO, SELECT_USER } from '../actions/types'; 

const initialState = {
    userInfo:'',
    selectedUser:'',
};


export default function(state = initialState, action){
    switch(action.type){
        case SET_USER_INFO:
            return{
                ...state,
                userInfo:action.payload
            }
        case SELECT_USER:
            return{
                ...state,
                selectedUser:action.payload
            }
        default:
            return state 
    }
}