import React, { Component } from 'react';
import store from '../../store'

class Help extends Component {

    state = {
        isAuthenticated: store.getState().auth.isAuthenticated,
        isAdmin: store.getState().auth.user.is_admin,
    }

    render() {
        let isLoggedIn = this.state.isAuthenticated;
        let isAdmin = this.state.isAdmin;
        let display = ''
        // let logDisplay = ''
        let admDisplay = ''

        if (isLoggedIn) {
            // logDisplay = 'none'
            display = 'block'
        } else {
            // logDisplay = 'block'
            display = 'none'
        }

        if (isAdmin) {
            admDisplay = 'block'
        } else {
            admDisplay = 'none'
        }

        let helpStyle = {
            textAlign: 'center',
            fontSize: '1.2rem',
            padding: '1rem'
        }

        let admStyle = {
            display: admDisplay,
            textAlign: 'left',
            fontSize: '1.2rem',
            padding: '1rem',
            paddingLeft: "4rem",
            paddingRight: "4rem"
        }

        let divStyle = {
            display: display,
            textAlign: 'left',
            fontSize: '1.2rem',
            padding: '1rem',
            paddingLeft: "4rem",
            paddingRight: "4rem"
        }

        const imgStyle = {
            paddingBottom: "4px",
        }

        // hiddenText()


        return (
            <div>
                <div style={helpStyle}>
                    <h1>Welcome to the help page, where you can find assistance for any aspect of our webpage</h1>
                    <hr />
                </div>

                {/* <div id="Login" style={loginStyle}>
                    <h2>Login</h2>
                    <p>1) Enter your email address and password, then click the "Login" button</p>
                    &emsp;<img
                        src="/helpAssets/Login.png"
                        width="400px"
                        alt="Name File"
                        style={imgStyle}
                    />
                    <p>If you are unable to log in, please contact your IT administrator, you may not have been given access.</p>
                    <p>If you have forgotten your password, please contact your IT administrator so they can reset your password.</p>
                    <p>If you are given a "Network Error" please contact your IT administrator.</p>
                    <hr />
                </div> */}

                <div id="Admin" style={admStyle}>
                    <h2>Admin View</h2>
                    <h5>Admins may view every user, when a user was last seen, every user's jobs, and reset a user's password.</h5>
                    <br />
                    <p>1) Navigate to "Admin View" in the navigation bar</p>
                    <p>&emsp;&emsp;You should now see a table with all users, as shown below.</p>
                    &emsp;&emsp;<img
                        src="/helpAssets/Admin.PNG"
                        width="900px"
                        alt="Name File"
                        style={imgStyle}
                    />
                    <p>&emsp;&emsp;The information shown on the table is explained below</p>
                    <p>&emsp;&emsp;• View Jobs</p>
                    <p>&emsp;&emsp;&emsp;&emsp;This allows you to see all the jobs of the specified user. You are also able to view/edit any of these jobs.</p>
                    <p>&emsp;&emsp;• Reset Password</p>
                    <p>&emsp;&emsp;&emsp;&emsp;This will reset the specified user's password and prompt you to create a new temporary password for them.</p>
                    <p>&emsp;&emsp;&emsp;&emsp;Please let the user know what their temporary password is, they will not be able to log in without it.</p>
                    <hr />
                </div>

                <div id="Create Transcription" style={divStyle}>
                    <h2>Create Transcription</h2>
                    <h5>Don't worry! Your job won't be deleted if you need to come back here mid creation :)</h5>
                    <br />
                    <p>1) Navigate to "Create Transcription" in the navigation bar</p>
                    <p>2) Begin by entering a name for your job. Any character is permitted.</p>
                    <img
                        src="/helpAssets/NameFile.PNG"
                        width="900px"
                        alt="Name File"
                        style={imgStyle}
                    />
                    <p>
                        3) Press the "Confirm Name" button.
                    </p>
                    <p>
                        &emsp;&emsp;Your page should look like the picture below, with your job name at the top, "Select Files to Upload", "Record Your Own" and "Cancel Job" buttons below.
                    </p>
                    &emsp;&emsp;<img
                        src="/helpAssets/CreateJob.PNG"
                        width="394px"
                        alt="Name File"
                        style={imgStyle}
                    />
                    <br />
                    <h4> "Select Files to Upload" </h4>
                    <p>
                        After selecting this you will be prompted to upload audio files from your local computer.
                        Select up to 8 files, then press "open." See the bottom on this section supported audio file types.<br />
                        &emsp;&emsp;If you have selected less than 8 files, you are able to add more by clicking the "Add Files to Transcription Jobs" button. <br />
                        Continue to step 5.
                    </p>
                    <h4> "Record Your Own" </h4>
                    <p>
                        After selecting this you will be prompted to choose up to eight audio devices. Each device you select will be used for recording.
                        Once you have selected the desired recording devices, press&nbsp; 
                        <img
                            src="/helpAssets/SubmitDevices.PNG"
                            width="130px"
                            alt="Name File"
                            style={imgStyle}
                        />.<br />
                        &emsp;&emsp;Make sure to press allow if your browser prompts you for microphone access.<br />
                        To begin recording press the&nbsp; 
                        <img
                            src="/helpAssets/StartBtn.PNG"
                            width="70px"
                            alt="Name File"
                            style={imgStyle}
                        /> button. To end recording press the&nbsp; 
                        <img
                            src="/helpAssets/StopBtn.PNG"
                            width="70px"
                            alt="Name File"
                            style={imgStyle}
                        /> button. 
                        After you end the recording a number of files corresponding to the number of recording devices you selected will appear. These files will be named [device name].wav.<br />
                        Continue to step 5.
                    </p>
                    <h4> "Cancel Job" </h4>
                    <p>
                        This will cancel your job. You will be prompted with a confirmation before it is canceled.
                    </p><br />
                    <p>
                        5) Once you have uploaded/recorded all the desired files, select the&nbsp;
                        <img
                            src="/helpAssets/UploadButton.PNG"
                            width="100px"
                            alt="Name File"
                            style={imgStyle}
                        /> button as shown here.
                    </p>
                    <p>
                        6) Select "Confirm" to continue. This should result in a popup box reading "Success, Upload complete, your transcription files will be available shortly!"
                    </p>
                    <p>
                        7) To view your transcripts, navigate to the "View Transcriptions" page.
                    </p>
                    <br />
                    <h4>Supported file types</h4>
                    <p>
                        .opus, .flac, .webm, .weba, .wav, .ogg, .m4a, .mp3, .oga, .mid, .amr, .aiff, .wma, .au, .acc
                    </p>
                    <hr />
                </div>

                <div id="View Transcription" style={divStyle}>
                    <h2>View Transcription</h2>
                    <h5>The view transcriptions page will give you an overview of each job you have created.</h5>
                    <br />
                    <p>Navigate to "View Transcriptions" in the navigation bar <br />
                        &emsp;&emsp;This will display the name of a job and its delete date. This page also allows you to view the status,
                        view/edit the transcription, download, or delete the transcription job.
                    </p>
                    <p>We will break down each component below:</p>
                    <li>Status (Only clickable before job completion)</li>
                    <p>&emsp;&emsp;This gives you information on the files that are associated with the job.</p>
                    <p>
                        &emsp;&emsp;Status types:<br />
                        &emsp;&emsp;&emsp;&emsp;Failed: The job failed to be transcribed.<br />
                        &emsp;&emsp;&emsp;&emsp;Completed: The job was successfully transcribed
                    </p>

                    <li>View/Edit</li>
                    <p>&emsp;&emsp;Allows you to view/edit the job. More information available on the View/Edit page</p>

                    <li>Download</li>
                    <p>&emsp;&emsp;by clicking download, a .txt file will be downloaded to your computer with the name of your job. This will contain the speaker and transcript.</p>

                    <li>Delete</li>
                    <p>&emsp;&emsp;By pressing delete, the job will be erased from your jobs. This is not reversable so please use carefully.</p>
                    <hr />
                </div>



                <div id="Edit Transcription" style={divStyle}>
                    <h2>Edit Transcription</h2>
                    <p>1) Navigate to "View Transcriptions"</p>
                    <p>2) Click on "View/Edit" next to a job</p>
                    <p>Here you are given information about the transcription job including:
                        <li>The start and stop time of a speaker</li>
                        <li>The speaker</li>
                        <li>Confidence</li>
                        <li>Content</li>
                    </p>
                    <p>Please note: The confidence is not always 100% accurate even when it says it is.</p>
                    <p>To edit the content, simply click inside the content box and begin your edits.</p>
                    <p>By pressing "Save Changes," any changes you made will be saved. <br />After changes have been saved, the confidence will change to "Last Edited By: [your user email]"</p>
                    <hr />
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        )
    }
}

export default Help