import {  SET_JOBS } from './types'; 
import axios from 'axios';
import { S3_AUDIO_BUCKET } from '../config';  
import store from '../store';
import { setError } from './errorActions';
import { adminSelectedUser } from './adminActions'; 

export const setJobs = async (props) => {
    /** 
    *   Gets a list of a users transcription
    *   jobs and saves them into application state
    *   If the user does not have any jobs they
    *   are redirected
    * 
    *   @param {JSON} props
    */ 
    try{
        let postData = {S3Bucket: S3_AUDIO_BUCKET }
        postData = adminSelectedUser(postData); 
        const jobs = await axios.post('getJobStatus',postData);
        if(jobs){ 
            store.dispatch({
                type: SET_JOBS,
                payload:jobs.data.data
            })
        }
        else{
            if(props){
                let user = store.getState().auth.user;
                //if(user.is_admin){
                //    props.history.push('/AdminPanel');
                //}
                //else{
                props.history.push('/');
                //}
            }
        }
    }
    catch(err){
        console.log(err); 
        setError('SET JOBS ERROR'); // previously Network ERROR
    }
}

export const clearJobs = () => {
    /** 
    *   Removes any jobs from 
    *   application state
    */
    store.dispatch({
        type: SET_JOBS,
        payload:''
    })
}

