import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { registerUser } from '../../actions/authActions';
import { connect } from 'react-redux'; 
import LoadingSpinner from '../layout/LoadingSpinner'; 
import { setError } from '../../actions/errorActions'; 

class Login extends Component{
    state = {
        isLoading:false,
        email: "",
        password: "",
    }

    handleSubmit = async (event) => {
        event.preventDefault(); 
        this.setState({isLoading:true}) 
        if(event.target.password.value !== event.target.confirmPassword.value){
            setError('Your Passwords Do Not Match');
            this.setState({isLoading:false});
        }
        else{
            let credentials = {
                email: event.target.email.value,
                password: event.target.password.value
            }
            let success = await registerUser(credentials);
            if(!success) this.setState({isLoading:false}); 
        } 
    }

    render(){
        console.log('isLoading', this.state.isLoading); 
        let isAuthenticated = this.props.auth.isAuthenticated; 
        if(isAuthenticated){
            window.location.href = '/'; 
        }
        let buttonText = this.state.isLoading ? <LoadingSpinner/> : "Register"; 
        return(
            <Container>
                <Form  onSubmit = {this.handleSubmit}>
                    <Form.Control onChange={this.handleChange} id = "email" placeholder = "Email Address"/> 
                    <Form.Control type = 'password' id = "password" placeholder = "Password"/> 
                    <Form.Control type = 'password' id = 'confirmPassword' placeholder = 'Confirm Password'/> 
                    <Button 
                        name='register'
                        variant="primary" 
                        type='submit'
                        disabled={this.state.isLoading}>
                            {buttonText}
                        </Button>
                </Form>
            </Container>
        )
    }
}


const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(Login); 