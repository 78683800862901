import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'; 
import React from 'react'; 
import { shortenString } from '../../functions/helperFunctions'; 

 function Popup(props) {
    let show = props.show; 
    let cancelButton = <Button onClick={props.cancel}>Cancel</Button> 
    let showCancel = props.cancel ? cancelButton : "";
    let header = shortenString(props.header);
    
    return (
      <Modal
        {...props}
        show = {show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header responsive>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3>
            {header}
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
              {props.body}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.closemodal}>Ok</Button>
          {showCancel}
        </Modal.Footer>
      </Modal>
    );
  }

export default Popup;


