import React, { Component } from 'react'; 
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavItem from 'react-bootstrap/NavItem'; 
import { LinkContainer } from 'react-router-bootstrap'; 
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import { getRandomNumber } from '../../functions/helperFunctions'; 
import { selectUser } from '../../actions/adminActions'; 
import { setJobs, clearJobs } from '../../actions/jobsActions'; 

class TheNavBar extends Component {
    onClick = () => {
        logoutUser(); 
        window.location.href = '/'; 
    }
    
    viewClicked = () => {
        if(this.props.isAdmin){
            clearJobs();
            selectUser(this.props.auth.user.email);
            setJobs();
        }
    }

    render(){
        let navItemClassName = 'ml-auto'; 
        let navLinkStyle = {
            color:"whitesmoke", 
            paddingRight:'2rem',
            fontSize:'1rem', 
        }
        // sou picture
        const image = (
            <img
                paddingleft='1.5rem'
                paddingright='1rem'
                src="sou_logo.png"
                width="200"
                height="125"
                alt="SOU logo"
                className={'justify-content-start'}
            />
        )
        // Nav bar links
        const logIn = (
            <NavItem key={getRandomNumber()} className={navItemClassName}>
                <LinkContainer to = '/login' style={navLinkStyle}>
                    <Nav.Link >Login</Nav.Link>
                </LinkContainer>
            </NavItem>
        );

        const logOut = (
            <NavItem key={getRandomNumber()}  className={navItemClassName}>
                <Nav.Link onClick= {this.onClick} style={navLinkStyle} >
                    Log Out 
                </Nav.Link>
            </NavItem>
        );

        const register = (
            <NavItem key={getRandomNumber()}  className={navItemClassName}>
                <LinkContainer to = '/register' style={navLinkStyle}>
                    <Nav.Link>Register</Nav.Link>
                </LinkContainer>
            </NavItem>
        );

        const createJob = (
            <NavItem key={getRandomNumber()}  className={navItemClassName}>
                <LinkContainer to ="CreateJob" style={navLinkStyle}>
                    <Nav.Link >Create Transcription </Nav.Link>
                </LinkContainer>
            </NavItem>
        );

        const viewEdit = (
            <NavItem key={getRandomNumber()}  className={navItemClassName}>
                <LinkContainer to = "ViewEdit" style={navLinkStyle}>
                    <Nav.Link onClick={this.viewClicked}>View Transcriptions </Nav.Link>
                </LinkContainer>
            </NavItem>
        );

        const adminView = (
            <NavItem key={getRandomNumber()}  className={navItemClassName}>
                <LinkContainer  to = "AdminPanel" style = {navLinkStyle}>
                    <Nav.Link >Admin View</Nav.Link>
                </LinkContainer>
            </NavItem>
        );

        const help = (
            <NavItem key={getRandomNumber()}  className={navItemClassName}>
                <LinkContainer  to = "Help" style = {navLinkStyle}>
                    <Nav.Link>Help</Nav.Link>
                </LinkContainer>
            </NavItem>
        );

        // render links based on permissions
        let isLoggedIn = this.props.auth.isAuthenticated;
        let isAdmin = this.props.isAdmin; 
        let navLinks; 

        if(!isLoggedIn){
            navLinks = [logIn,register];
        }

        else if(isLoggedIn){
            navLinks = [createJob, viewEdit, help, logOut];

            if(isAdmin)
                navLinks.unshift(adminView);
        }

        return(
            <Navbar expand = 'lg' bg='primary'className = 'justify-content-sm-between'>
                <Navbar.Brand expand = {'true'}>
                    {image}
                </Navbar.Brand>
                <Nav>
                    <Navbar.Collapse>
                        {navLinks}
                    </Navbar.Collapse>
                </Nav>
            </Navbar>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    isAdmin: state.auth.user.is_admin
})

export default connect(mapStateToProps)(TheNavBar); 