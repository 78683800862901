import { combineReducers } from 'redux';
import authReducer from './authReducer'; 
import createJobReducer from './createJobReducer'; 
import textReducer from './textReducer'; 
import jobsReducer from './jobsReducer'; 
import errorReducer from './errorReducer'; 
import adminReducer from './adminReducer';




export default combineReducers({
    auth:authReducer,
    createJob:createJobReducer, 
    text:textReducer,
    jobs:jobsReducer,
    error:errorReducer,
    admin:adminReducer
})

