
/* 
*   This file defines a namespace for all of the application's actions
*/

//AUTH
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOG_OUT = 'LOG_OUT';
export const SHOW_MODAL = 'SHOW_MODAL'; 

//CREATE TRANSCRIPTION JOB 
export const SET_AUDIO_FILES = 'SET_AUDIO_FILES';
export const UPLOAD_AUDIO_FILES = 'UPLOAD_AUDIO_FILES'; 
export const REMOVE_AUDIO_FILE = 'REMOVE_AUDIO_FILE'; 
export const SET_JOB_NAME = 'SET_JOB_NAME'; 
export const SET_UPLOADING = 'SET_UPLOADING'; 
export const RESET_JOB = 'RESET_JOB'; 
export const CONFIRM_JOB = 'CONFIRM_JOB'; 


//JOBS
export const GET_JOBS = 'GET_JOBS'; 
export const SET_JOBS = 'SET_JOBS'; 
export const SELECT_JOB = 'SELECT_JOB'; 
export const DELETE_JOB = 'DELETE_JOB'; 
export const CANCEL_JOB = 'CANCEL_JOB'; 
export const START_TRANSCRIPTION = 'START_TRANSCRIPTION'; 


//ERROR
export const SET_ERROR = 'SET_ERROR'; 
export const CLEAR_ERROR = 'CLEAR_ERRORS'; 

//TEXT
export const IS_CHANGED = 'IS_CHANGED'
export const SET_TRANSCRIPT = 'SET_TRANSCRIPT'; 
export const CLEAR_TRANSCRIPT = 'CLEAR_TRANSCRIPT'; 
export const EDIT_SENTENCE = 'EDIT_SENTENCE'; 

//ADMIN
export const SET_USER_INFO = 'SET_USER_INFO'; 
export const SELECT_USER = 'SELECT_USER'; 