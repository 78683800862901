import React, { Component } from 'react'; 
import Toast from 'react-bootstrap/Toast'; 


class Notify extends Component{
    render(){
        return(
            <div>
            <Toast onClose={this.props.onClose}
                style = {{
                    color:this.props.color,
                    backgroundColor:"whitesmoke",
                    fontWeight:'bold',
                    fontFamily:'Roboto',
                    fontSize:'1.75em',
                    boxShadow:'0px 0px',
                    border:'none'
                }}
                show={this.props.show} 
                delay={2000} autohide>
                    <Toast.Body> {this.props.body} </Toast.Body>
            </Toast>
            </div>
        )
    }
}

export default Notify; 