import React, {Component} from 'react';

class FooterSpace extends Component{
	render(){
		const spaceStyle = {
			backgroundColor: "white",
			position: "static",
			bottom: "0px",
			left: "0",
			height:"100px",
			width:"100%",
			lineHeight: ".4",
			paddingLeft:"4px",
			paddingTop:"4px"
		}
		return(
			<>
			<div style={spaceStyle}></div>
			</>
		)
	};
}
export default FooterSpace;
