import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';

class LoadingSpinner extends Component {
    render(){
        return(
            <Spinner 
                textalign='center'  
                animation = 'border' 
                variant ='danger' 
                size ='sm' 
                style = {{margin:'4'}}
                />

        )
    }
}

export default LoadingSpinner;