import { SET_TRANSCRIPT, CLEAR_TRANSCRIPT, EDIT_SENTENCE, IS_CHANGED } from '../actions/types'; 
import { isEmpty } from '../functions/helperFunctions';

const initialState = {
    jobName:'', 
    hasText:false,
    text: '',
    isEdited: false,
}

export default function(state = initialState, action){
    switch(action.type){
        case SET_TRANSCRIPT:
            let hasText = !isEmpty(action.payload); 
            return{
                ...state,
                jobName:action.payload.jobName,
                hasText: hasText,
                text: action.payload.lines,
                isEdited: false
            }
        case CLEAR_TRANSCRIPT:
            return{
                ...state,
                hasText:false,
                text: '', 
                isEdited:false
            }
        case IS_CHANGED: 
            return{
                ...state,
                isEdited:action.payload
            }
        case EDIT_SENTENCE:
             return {
                 ...state,
                 text:action.payload,
                 isEdited:action.isEdited
            }
        default: 
            return state; 
    }
}