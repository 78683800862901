import React, { Component } from 'react';
import Popup from '../layout/Popup'; 
import Form from 'react-bootstrap/Form';
import { resetPassword } from '../../actions/authActions'; 
import { setError } from '../../actions/errorActions'; 
import { isEmpty } from '../../functions/helperFunctions';
import PageLoading from '../layout/PageLoading'; 


class ResetPassword extends Component{
    state = {
        password:'',
        confirm:'',
        loading: false,
        upated: false, 
    }

    handlePassword = (e) => {
        this.setState({
            password: e.target.value
        });
    }

    handleConfirm = (e) => {
        this.setState({
            confirm: e.target.value
        });
    }

    finishUpdate = () => {
        this.setState({
            password:'',
            confirm:'',
            loading:false,
            updated: false
        })
        this.props.cancel();
    }

    updatePassword = async () => {
        let password = this.state.password;
        let confirm = this.state.confirm;
        let cancel = this.props.cancel;
        let selectedUser = this.props.selectedUser;
        if (password !== confirm){
            cancel();
            setError("Your passwords do not match"); 
        }
        else if(isEmpty(password) || isEmpty(confirm)){
            cancel();
            setError("Missing Required Fields");
        }
        else {
            this.setState({loading:true}); 
            let updated = await resetPassword(selectedUser, password); 
            if(updated){
                this.setState({
                    updated: true,
                    loading: false, 
                 })
            }
        }
    }

    render(){
        let selectedUser = this.props.selectedUser;
        let bodyMsg = "Enter Temporary Password For: " + selectedUser;
        let successMSG1 = selectedUser +"'s password has been updated";
        let successMSG2 = "Please give them their temporary password"; 
        let successBody = (
            <div style = {{textAlign:'center'}}>
                <div>{successMSG1}</div>
                <div>{successMSG2}</div>
            </div>
        ) 
        let form = (
            <Form>
                <div style = {{textAlign:'center'}}>{bodyMsg}</div>
                <Form.Control
                    type = 'password'
                    id = "password"
                    placeholder = "Password"
                    onChange = {this.handlePassword}
                /> 
                <Form.Control
                    type = 'password'
                    id = 'confirmPassword'
                    placeholder = 'Confirm Password'
                    onChange = {this.handleConfirm}
                /> 
            </Form>
        )
        let displayBody = this.state.loading ? <PageLoading tiny = {true}/> : form;
        displayBody = this.state.updated ? successBody : displayBody; 
        let ok = this.state.updated ? this.finishUpdate : this.updatePassword; 
        let formModal = (
            <Popup
                header = "Password Reset"
                body = {displayBody}
                show = {this.props.show}
                cancel = {this.finishUpdate}
                closemodal = {ok}
            />
        )
        return formModal
    }
}

export default ResetPassword;