import React, { Component } from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import { getRandomNumber } from './functions/helperFunctions'; 

//Components 
import TextEdit from './components/viewEdit/TextEdit'; 
import CreateJob from './components/newJob/CreateJob';
import Help from './components/helpPanel/Help';
import TheNavBar from './components/layout/TheNavBar';
import Header from './components/layout/Header';  
import Login from './components/auth/Login'; 
import Register from './components/auth/Register'; 
import ViewEdit from './components/viewEdit/ViewEdit';
import Footer from './components/layout/Footer';
import DisplayError from './components/error/DisplayError'; 
import Admin from './components/admin/Admin'; 
import UpdateTempPassword from './components/auth/UpdateTempPassword'; 

//Redux 
import { Provider } from 'react-redux';
import store from './store'

//Auth 
import { checkToken, logoutUser } from './actions/authActions'; 

//style
import 'bootswatch/dist/lux/bootstrap.min.css';

// find/validate auth jwt
checkToken(); 

class App extends Component{
  state = {
    isAuthenticated:store.getState().auth.isAuthenticated,
    email:store.getState().auth.user.email,
    isAdmin: store.getState().auth.user.is_admin,
    isTempPassword: store.getState().auth.user.temp_password, 
  }

  render(){ 
    // Create App routes 
    const createJob = <Route key = {getRandomNumber()} exact path = "/CreateJob" component = { CreateJob }/>
    const edit = <Route key = {getRandomNumber()} exact path = "/Edit" component = { TextEdit }/> 
    const logIn = <Route key = {getRandomNumber()} exact path = "/Login" component = { Login }/> 
    const viewEdit = <Route key = {getRandomNumber()} exact path = "/ViewEdit" component = { ViewEdit }/> 
    const authHome = <Route key = {getRandomNumber()} exact path = "/" component = { CreateJob }/> 
    const notAuthHome = <Route key = {getRandomNumber()} exact path = "/" component = { Login }/> 
    const adminView = <Route key = {getRandomNumber()} exact path = "/AdminPanel" component = { Admin }/>
    const register = <Route key = {getRandomNumber()} exact path = "/Register" component = { Register } />  
    const help = <Route key = {getRandomNumber()} exact path = "/Help" component = { Help }/>
    
    
    let loggedInMsg = ''; 
    let isLoggedIn = this.state.isAuthenticated;
    let isAdmin = this.state.isAdmin; 
    let isTempPassword = this.state.isTempPassword;
    let routes; 

    //If password has been reset force password change 
    if(isTempPassword){
      routes = (
        <UpdateTempPassword
          selectedUser = {this.state.email}
          show = {isLoggedIn}
          cancel = {logoutUser}
        />
      )

    }
    // Enable routes based on authentication
    else if (!isLoggedIn){
      routes = [logIn, notAuthHome, register];
    }
    else if (isLoggedIn){
      loggedInMsg = 'Logged in as: ' + this.state.email;
      routes = [createJob, edit, viewEdit, authHome, help];

      if(isAdmin){
        routes.push(adminView);
        loggedInMsg = loggedInMsg + ' (ADMIN)'; 
      }
    }
   
    return (
      <Provider store = {store}>
        <Header/> 
          <Router>
            <TheNavBar/>
              <h6 style={{textAlign:'left',paddingTop:'1rem'}}>
                {loggedInMsg}
              </h6>
            <hr/>
            {routes}
          </Router>
        <Footer/>
        <DisplayError/>
      </Provider>
      
    );
  }
}

export default App; 
