import React, { Component } from 'react'; 
import Table from 'react-bootstrap/Table'; 
import Button from 'react-bootstrap/Button'; 
import { downloadTranscript, deleteTranscript } from '../../actions/textActions'; 


class JobsTable extends Component{
    state = { isDeleting: false }

    download = (e) => {
        downloadTranscript(e.target.id);  
    }

    delete  = async (e) => {
        this.setState({ isDeleting : true });
        let resp = await deleteTranscript(e.target.id, this.props.props);
        if(resp){
            this.setState({isDeleting:false});
        }
        else{
            let myProps = this.props.props;
            myProps.history.push('/ViewEdit'); 
        }

    }

    render(){
        let jobsDone ='COMPLETED'; 
        let jobs = this.props.jobs;
        let tableBody = []; 
        let tdStyle = { fontWeight: 'bold' }

        for(let i = 0; i < jobs.length; i++){
            let notDone = jobs[i].status === 'IN_PROGRESS'; 
            let details = (
                <Button size='sm' id={i} onClick = {this.props.viewSubJobs}>
                    Details
                </Button>
            )
            let tRow = (
                <tr key={i}>
                    <td>{jobs[i].name}</td>
                    <td style={tdStyle}>
                        {notDone ? details : jobs[i].status}
                    </td>
                    <td style={tdStyle}> 
                        {notDone ? 'Not Determined' : jobs[i].delete_date} 
                    </td>
                    <td>
                        <Button size='sm'
                        onClick={this.props.editTranscript}
                        disabled={jobs[i].status !== jobsDone}
                        id={jobs[i].name}>
                            View/Edit
                        </Button>
                    </td>
                    <td>
                        <Button size='sm'
                            onClick={this.download}
                            id={jobs[i].name}
                            disabled={notDone}
                        >Download
                        </Button>
                    </td>
                    <td>
                        <Button size='sm'
                            onClick={this.delete} 
                            id={jobs[i].name}
                            disabled={notDone || this.state.isDeleting}
                        >Delete
                        </Button>
                    </td>
                </tr>
            )
            tableBody.push(tRow); 
        }
        let table = (
           <Table striped bordered hover responsive size='sm' style={{textAlign:'center'}}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Delete Date</th>
                        <th>View/Edit</th>
                        <th>Download</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {tableBody}
                </tbody>
            </Table>
        )
        return(<div style={{display:'block', paddingBottom: '110px'}}>{table}</div>); 
    }
}



export default JobsTable; 