import React, { Component } from 'react'; 
import { connect } from 'react-redux';  
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col'; 
import { setJobName } from '../../actions/createJobActions'; 
import Button from 'react-bootstrap/Button';

class NameJob extends Component{
    handleSubmit = (event) => {
        event.preventDefault(); 
        console.log(event.target.jobName.value); 
        setJobName(event.target.jobName.value); 
    }
    
    render(){
        let enterName = (
            <Form  inline onSubmit={this.handleSubmit}>
                <Col  md={8}>
                <Form.Control style={{width:'100%'}} id="jobName" placeholder="Enter A Name For Your New Transcription Job"/>
                </Col>
                <Col>
                <Button type="submit">Confirm Name</Button>
                </Col>
            </Form>
        )
        let displayName = (<h6>{this.props.jobName}</h6>);
        let hasJobName = !this.props.jobName;
        let returnValue = hasJobName ? enterName : displayName ;
        return(
            <div style={this.props.style}>{returnValue}</div>
        )
    }

}

const mapStateToProps = state => ({jobName:state.createJob.jobName})


export default connect (mapStateToProps)(NameJob); 