import React, { Component } from 'react'; 

class Footer extends Component{
    render(){
        const pStyle = {
            fontSize: "14px",
            color: "white",
            textIndent: "5px",
            fontFamily:"Nunito Sans",
            lineheight: ".8", 
            textAlign: "left"
        }
        const linkStyle = {
            color: "white",
            visited: "white"
        }
        const footerStyle = {
            backgroundColor: "black",
            position: "fixed",
            bottom: "0px",
            left: "0",
            height: "105px",
	    width: "100%",
            lineHeight: ".4",
            paddingLeft: "4px",
            paddingTop: "4px",
            textAlign: "left"
        }
        const imgStyle = {
            paddingBottom: "4px",
        }
        return(
            <>
            <footer style={footerStyle}> 
                <a href="https://sou.edu" target="_blank" rel="noopener noreferrer">
                <img 
                    src="SOU-LOGO-HZ-186-REV.jpg"
                    width="140px"
                    alt="SOU logo"
                    style={imgStyle}
                /></a>
                <p style={pStyle}> Developed by: &nbsp;
                    <a
                        style={linkStyle}
                        href="https://www.linkedin.com/in/liam-erickson/" target="_blank" rel="noopener noreferrer">Liam Erickson</a>,&nbsp;                                    
                    <a
                        style={linkStyle}
                        href="https://www.linkedin.com/in/seann-moser-51013a19a/" target="_blank" rel="noopener noreferrer">Seann Moser</a>,&nbsp;
                    <a
                        style={linkStyle}
                        href="https://www.linkedin.com/in/platt-sam/" target="_blank" rel="noopener noreferrer">Sam Platt</a>,&nbsp;                         
                    <a
                        style={linkStyle}  
                        href="https://www.linkedin.com/in/matt-ruggiero-37124393/" target="_blank" rel="noopener noreferrer">Matt Ruggiero</a>,&nbsp;
                    <a 
                        style={linkStyle} 
                        href="https://www.linkedin.com/in/isaac-stoutenburgh/" target="_blank" rel="noopener noreferrer">Isaac Stoutenburgh</a>,&nbsp;
                    <a
                        style={linkStyle}
                        href="https://www.linkedin.com/in/joshua-yoon-056045206/" target="_blank" rel="noopener noreferrer">Joshua Yoon</a>
                </p>
                <p style={pStyle}>SOU Computer Science Program</p>
                <p style={pStyle}>Developed under the supervision of Dr. Maggie Vanderberg</p>
            </footer>
            </>
        )
    }
}
export default Footer;
