import { SET_ERROR, CLEAR_ERROR } from '../actions/types'; 

const initialState = {
    isError:false,
    errorMSG:'',
}

export default function(state = initialState, action){
    switch(action.type){
        case SET_ERROR:
            console.log('SET_ERROR');
            return {
                isError:true,
                errorMSG:action.payload 
            }
        case CLEAR_ERROR:
            console.log('CLEAR_ERROR'); 
            return{
                isError:false,
                errorMSG:''
            }
        default:
            return state; 
    }   

}