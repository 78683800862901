import { SET_ERROR, CLEAR_ERROR } from './types';
import store from '../store'; 


export const setError = (errorMSG) => {
    /** 
    *   Saves error message into 
    *   application state
    * 
    *   @param {string} errorMSG
    */
    store.dispatch({
        type:SET_ERROR,
        payload:errorMSG
    })
}

export const clearError = () => {
    /** 
    *   Removes error message from
    *   applicatin state
    */ 
    store.dispatch({
        type:CLEAR_ERROR
    })
}