import React from 'react'; 
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';


function ConfirmJob(props){
    console.log(props.show); 
    let show = props.show;
    return (
        <Modal
          {...props}
          show = {show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          
        >
          <Modal.Header >
            <Modal.Title  id="contained-modal-title-vcenter" >
              {props.header}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>
                {props.body}
            </h5>
          </Modal.Body>
          <Modal.Footer >
            <Button onClick={props.confirm}>Confirm</Button>
            <Button onClick={props.cancel}>Cancel</Button>
          </Modal.Footer>
        </Modal>
    );
}

export default ConfirmJob;