import { SET_AUDIO_FILES, REMOVE_AUDIO_FILE, SET_JOB_NAME, SET_UPLOADING, RESET_JOB, CONFIRM_JOB } from '../actions/types'; 
import { isEmpty } from '../functions/helperFunctions';

const initialState = {
    files:[],
    jobName:'', 
    isUploading:false,
    confirmation:false,
    success:false,

}

export default function(state = initialState, action){
    switch(action.type){
        case SET_AUDIO_FILES:
        return{
            ...state,
            files:action.payload,
        }
        case CONFIRM_JOB:
            return{
                ...state,
                confirmation:action.payload
            }
        case REMOVE_AUDIO_FILE:
            delete state.files[action.payload]; 
            if(isEmpty(state.files)){
                return {
                    ...state,
                    files:[],
                    success:true
                }
            }
            return {
                ...state, 
                files: {...state.files}
            }
        case SET_JOB_NAME: 
            return {
                ...state,
                jobName: action.payload 
            }
        case SET_UPLOADING:
            return {
                ...state,
                isUploading:action.payload 
            }
        case RESET_JOB:
            return {
                ...initialState
            }
        default:
            return state; 
    }
}