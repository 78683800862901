import React, { Component } from 'react';
import FileList from './FileList';
import SelectFile from './SelectFile';
import Upload from './UploadFile';
import { Container, Col } from 'react-bootstrap';
import NameJob from './NameJob';
import { confirmJob, resetJob, setUploading, uploadAudioFiles } from '../../actions/createJobActions';
import ConfirmJob from './ConfirmJob';
import { connect } from 'react-redux';
import Popup from '../layout/Popup';
import { isEmpty } from '../../functions/helperFunctions';
import FooterSpace from '../layout/FooterSpace';




class CreateJob extends Component {
    confirm = () => {
        confirmJob(false)
        setUploading(true);
        let files = this.props.files;
        let keys = Object.keys(this.props.files);
        keys.forEach(key => {
            uploadAudioFiles(files[key], this.props.jobName, this.props.email);
        })

    }

    // reset = () => {
    //     confirmJob(false)
    // }

    reset = () => resetJob()

    render() {
        let compStyle = {
            textAlign: 'center',
            fontSize: '1.2rem',
            padding: '0.5rem',
            width: '30%',
            margin: '0 auto'
        }
        let confStyle = {
            textAlign: 'center',
            fontSize: '1.2rem',
            padding: '0.5rem',
            margin: '0 auto'
        }
        let hasFiles = !isEmpty(this.props.files);
        let hasName = !isEmpty(this.props.jobName);
        let displayUpload = hasFiles && hasName ? <Upload style={compStyle} /> : '';
        let displayAddFile = hasName ? <SelectFile style={compStyle} /> : '';
        let displayFooterSpace = hasFiles && hasName ? <FooterSpace style={compStyle} /> : '';
        let colStyle = { padding: '1rem' }
        let mHeader = (<center> Files to be uploaded </center>);
        let mBody = (<center><FileList /></center>)
        return (
            <div>

                <Container>
                    <Col />
                    <Col style={colStyle}>
                        <NameJob style={confStyle} />
                        <FileList style={compStyle} />
                        {displayAddFile}  {/* This is the button causing issues */}

                        {displayUpload}
                        {displayFooterSpace}
                    </Col>
                    <Col />

                    <ConfirmJob
                        show={this.props.confirmation}
                        header={mHeader}
                        body={mBody}
                        confirm={this.confirm}
                        cancel={this.reset}
                    />
                    <Popup
                        show={this.props.success}
                        header="Success"
                        body="Upload complete, your transcription files will be available shortly!"
                        onClick={this.reset}
                    />
                </Container>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    email: state.auth.user.email,
    confirmation: state.createJob.confirmation,
    files: state.createJob.files,
    jobName: state.createJob.jobName,
    success: state.createJob.success
})
export default connect(mapStateToProps)(CreateJob); 
