import axios from 'axios';
import { API_URL } from '../config'; 
import { setError } from '../actions/errorActions'; 
import { setCurrentUser, logoutUser } from '../actions/authActions'; 
import jwt_decode from 'jwt-decode';


const setAuthHeader = () => {
    /** 
    *   If a valid jwt is present
    *   in local storage set it 
    *   in the axios auth header
    */
    let token = localStorage.jwtToken; 
    if (token) {
        const decoded = jwt_decode(token);
        if(!decoded) logoutUser();
        axios.defaults.headers.common['Authorization'] = token;
        setCurrentUser(decoded); 
    }
}

export const axiosLogout = () => {
    /** 
    *   Remove authorization token if
    *   the user logs out
    */
    delete axios.defaults.headers.common['Authorization'];
}


const interceptRequest = (request) => {
    /** 
    *   Set authorization header before 
    *   any http request is made
    * 
    *   @param {JSON} request 
    *   @return {JSON}
    */
    setAuthHeader();
    console.log('Sending Request', request); 
    return request; 
}


const interceptResponse = (response) => {
    /**  
    *   Retrieves auth token from http response
    *   and saves it into applicatin state
    *   and local storage
    * 
    *   Retrieves any errors returned in 
    *   the response and if found saves them
    *   in application state
    * 
    *   @param {JSON} response
    *   @return {JSON} 
    */
    console.log('Got Response', response); 
    
    // Make sure the most recent token is used 
    let refreshed = response.data.token; 
    if(!refreshed){
        refreshed = localStorage.jwtToken;
    }
    if(refreshed){
        localStorage.setItem('jwtToken',refreshed);
        setCurrentUser(jwt_decode(refreshed)); 
    }

    // Catch all custom errors returned from API 
    let data = response.data;
    let error1 = data.data ? data.data.ERROR : false; 
    let error2 = data ? data.ERROR : false; 
    if(error1){
        setError(error1); 
        return false; 
    } 
    if(error2){
        setError(error2);
        return false;
    }
    return response; 
}


//Global Axios configuration 
export const configureAxios = () => {
    /** 
    *   Configures http client
    */
    setAuthHeader();
    
    // Set base URL for http requests 
    axios.defaults.baseURL = API_URL; 

    // proccess all http requests
    axios.interceptors.request.use(request => {
        return interceptRequest(request); 
    });

    // proccess all http responses 
    axios.interceptors.response.use(response => {
        return interceptResponse(response)
    });
}
