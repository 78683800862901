import store from '../store'; 
import { SET_AUDIO_FILES , REMOVE_AUDIO_FILE, SET_JOB_NAME, SET_UPLOADING, RESET_JOB, CONFIRM_JOB} from './types'; 
import { MAX_FILES,S3_AUDIO_BUCKET, TRANSCRIBE } from '../config'; 
import axios from 'axios'; 
import { setError } from './errorActions';





export const setAudioFiles = (files) => {
    /** 
    *   Creates a list of files to be uploaded
    *   and saves them into applicatin state 
    * 
    *   @param {JSON} files 
    *   @return {boolean}
    */
    let keys = Object.keys(files); 
    if(keys.length > MAX_FILES) {
        setError('Maximum Audio Files Allowed is ' + MAX_FILES); 
        return false; 
    }
    store.dispatch({
        type: SET_AUDIO_FILES,
        payload: files
    })
    return true;
}


export const resetJob = () => {
    /** 
    *   Removes all new job info 
    *   from application state 
    */
    store.dispatch({
        type:RESET_JOB,
    })
}

export const removeAudioFile = (fileName) => {
    /** 
    *   Removes audio file from list of files
    *   to be uploaded
    * 
    *   @param {string} fileName
    */
    store.dispatch({
        type: REMOVE_AUDIO_FILE, 
        payload: fileName 
    })

}

export const setJobName = (jobName) => {
    /**  
    *   Saves the name of a new job in 
    *   application state
    * 
    *   @param {string} jobName
    *   @return {undefined}
    */
    store.dispatch({
        type: SET_JOB_NAME,
        payload: jobName
    })
}

export const setUploading = (isUploading) => {
    /** 
    *   Tracks wether files are currently 
    *   uploading or not
    * 
    *   @param {boolean} isUploading
    */
    store.dispatch({
        type: SET_UPLOADING,
        payload: isUploading 
    })
}

export const startTranscription = () => {
    /** 
    *   Starts to transcribe all files in 
    *   the users Directory in the
    *   S3_AUDIO_BUCKET
    */
    try{
        const postData = { S3Bucket: S3_AUDIO_BUCKET}; 
        axios.post('startTranscribe', postData);
    }
    catch(e){
        console.log(e); 
        setError('START TRANSCRIPTION ERROR'); // originally Network ERROR
    }
}

const makeForm = (presignedPost , file ) => {
    /** 
    *   populates the form data after  a 
    *   presigned post is granted 
    * 
    *   @param {JSON} presignedPost
    *   @param {Object} file
    *   @return {JSON} 
    */
    const formData = new FormData();
    Object.keys(presignedPost.data.fields).forEach(key => {
        formData.append(key,presignedPost.data.fields[key]); 
    })
    formData.append('file',file); 
    return formData;  

}

export const uploadAudioFiles = async (file,jobName,userName) =>{
    /**
    *   Requests temporary authorization to upload to the S3_
    *   AUDIO_BUCKET The length of time this authorization is
    *   good for is defined by the back end
    *   IF granted, uploads all audio files in the request
    * 
    *   @param {Object} file
    *   @param {string} jobName
    *   @param {string} username 
    */
    try{
        const postData = {
            S3Bucket:S3_AUDIO_BUCKET,
            S3Key: file.name,
            jobName: jobName,
            userName: userName
        }
        const presignedPost = await axios.post('upload',postData); 
        let postForm = makeForm(presignedPost,file); 
        setUploading(true); 
        delete axios.defaults.headers.common['Authorization'];
        let uploadResponse = await axios.post(presignedPost.data.url,postForm); 
        if(uploadResponse.status === 204){
            removeAudioFile(file.name); 
            if(TRANSCRIBE) startTranscription(file.name);
        }
    }
    catch(e){  
        console.log(e); 
        setUploading(false); 
        setError(e.toString()); // originally Network ERROR
    
    }
}

export const confirmJob = (showModal) => {
    /** 
    *   Used to track if the confirm new job 
    *   message should be displayed
    * 
    *   @param {boolean} showModal
    */
    store.dispatch({
        type:CONFIRM_JOB,
        payload:showModal
    })
}
