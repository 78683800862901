import { SET_JOBS } from '../actions/types'; 




const iniitialState = {
    jobs:[]
}


export default function(state = iniitialState, action){
    switch(action.type){
        case SET_JOBS:
            return{
                ...state,
                jobs:action.payload, 
            }
        default:
            return state; 
    }
}


