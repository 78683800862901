import React, { Component } from 'react'; 
import Table from 'react-bootstrap/Table'; 


class SubJobsTable extends Component{
    render(){
        let createTbody = (subJobs) => {
            let rows = []; 
            let i = 1; 
            this.props.subJobs.forEach(subJob => {
                let row = (
                    <tr key={subJob.name}>
                        <td>{i++}</td>
                        <td>{subJob.name}</td>
                        <td>{subJob.status}</td>
                    </tr>
                )
                rows.push(row); 
            })
            return rows; 
        }

        let table = (
            <Table striped responsive bordered size='sm' style={{textAlign:'center'}}>
                <thead>
                    <tr>
                        <th>File Number</th>
                        <th>Filename</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                        {createTbody(this.props.subJobs)} 
                    </tbody>
                
            </Table>
        )
        return(<div>{table}</div>); 
    }
}

export default SubJobsTable; 