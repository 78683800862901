export  const isEmpty = (value) => {
    let returnValue = value === undefined || value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0)||
    (typeof value === 'string' && value.trim().length === 0);

    return returnValue;
}

export const shortenString = (stringToCheck) => {
    if(stringToCheck.length > 35)
        return stringToCheck.slice(0,35) + '...';
    return stringToCheck;
}

export const getRandomNumber = () => {
    return Math.floor(Math.random() * 100000); 
}