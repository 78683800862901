import React, { Component } from 'react'; 


class Header extends Component{
    render(){
            return(
                <>
                    <h1 style={{paddingTop:".5em", paddingBottom:".5em"}}>
                        <center>
                            Conversational Transcript
                        </center>
                    </h1>
                </>
        )
    }
}


export default Header;