import { SET_TRANSCRIPT, CLEAR_TRANSCRIPT, EDIT_SENTENCE, IS_CHANGED } from './types'; 
import { API_URL,S3_AUDIO_BUCKET } from '../config'; 
import store from '../store';
import axios from 'axios'; 
import { isEmpty } from '../functions/helperFunctions'
import { setError } from './errorActions';
import {setJobs} from './jobsActions.js';
import { adminSelectedUser } from './adminActions';



export const setTranscript = async (jobName) => {
    /** 
    *   Get Transcription From Database
    *   and save it into application state
    * 
    *   @param {string} jobName
    */
    let postData = {
        jobName:jobName,
        S3Bucket:S3_AUDIO_BUCKET
    }
    postData = adminSelectedUser(postData); 
    try{
        let transcriptJSON = await axios.post(API_URL+'/getTranscript',postData);
        if(!transcriptJSON) return false; 
        let payload = { 
            lines: transcriptJSON.data.lines,
            jobName: jobName
        }
        if(isEmpty(payload.lines)){
            setError('You Have Erased This Transcription'); 
            deleteTranscript(jobName); 

        }
        store.dispatch({
            type:SET_TRANSCRIPT,
            payload:payload
        })
    }catch(error){
        console.log("setTranscript: \n"+error); 
        setError('SET TRANSCRIPT ERROR'); // previously Network Error
    }
}


export const clearTranscript = () => {
    /** 
    *   Remove transcript from application state 
    */
    store.dispatch({
        type:CLEAR_TRANSCRIPT,
    })
}

export const isChanged = (hasUnsaved) => {
    /** 
    *   Tracks wether the transcript in application 
    *   state has been changed or not
    * 
    *   @param {boolean} hasUnsaved
    */
    store.dispatch({
        type:IS_CHANGED,
        payload:hasUnsaved
    })
}

export const editSentence = async (sentence) => {
    /** 
    *   Edit transcript in application state
    * 
    *   @param {JSON} sentence
    */
    let { newSentence, index, key } = sentence; 
    let st = store.getState(); 
    let newText = st.text; 
    let oldSentence = st.text.text[index][key][0].sentence; 
    let isEdited = !(oldSentence === newSentence); 
    if(isEdited){
        let email = st.auth.user.email;
        let lastEdited = "Last Edited By: " + email;
        newText.text[index][key][0].edited = true; 
        newText.text[index][key][0].sentence = newSentence; 
        newText.text[index][key][0].avg_confidence = lastEdited; 
        store.dispatch({
            type:EDIT_SENTENCE,
            payload:newText.text,
            isEdited: isEdited
        })
    }
}


export const saveChanges = async (postData) => {
    /** 
    *   Save transcription that is in application 
    *   state to the DB 
    * 
    *   @param {JSON} postData
    *   @return {boolean} 
    */
    try{
        let lines  = [...postData.transcript.lines]; 
        let noEmpties = []; 
        Object.keys(lines).forEach(each => {
            let key = Object.keys(lines[each])[0];
            let sentence = lines[each][key][0].sentence;
            let notMT = !isEmpty(sentence.replace(/\s/g,'')); 
            if (notMT) noEmpties.push(lines[each]); 
        })
        postData.transcript.lines = noEmpties;
        postData['S3Bucket'] = S3_AUDIO_BUCKET;
        postData = adminSelectedUser(postData); 
        let saved = await axios.post(API_URL+'/saveTranscript',postData);
        if(!saved) return false; 
        if (noEmpties.length !== lines.length)
            setTranscript(postData.jobName); 
        return true;
    }
    catch(err){
        console.log(err);
        setError('SAVE CHANGES ERROR'); // previously Network Error
    } 
}


export const downloadTranscript = async (jobName) => {
    /** 
    *   Downloads transcript from the DB as a .txt file
    * 
    *   @param {string} jobName
    */
    try{
        let postData = {
            jobName:jobName,
            S3Bucket: S3_AUDIO_BUCKET,
            responseType:'blob'
        }
        postData = adminSelectedUser(postData); 
        let response = await axios.post('downloadTranscript',postData);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',jobName+'.txt');// or any other extension
        document.body.appendChild(link); 
        link.click();
    }
    catch(err){
        console.log(err); 
        setError('DOWNLOAD TRANSCRIPT ERROR'); // previously Network Error
    } 
}

export const deleteTranscript = async (jobName,props) => {
    /** 
    *   Delete Transcript from DB
    *   
    *   @param {string} jobName
    *   @param {JSON} props
    *   @return {JSON}
    */
    try{
        let postData = {
            jobName:jobName,
            S3Bucket:S3_AUDIO_BUCKET,
        }
        postData = adminSelectedUser(postData); 
        let delete_response = await axios.post('deleteTranscript',postData);
        setJobs(props);
        return delete_response; 
 
        
    }
    catch(err){
        console.log(err); 
        setError('Delete ERROR');
    } 
}    
