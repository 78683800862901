import React, { Component } from 'react';
import { setUserInfo, selectUser } from '../../actions/adminActions'; 
import { connect } from 'react-redux';
import { isEmpty } from '../../functions/helperFunctions';
import Button from 'react-bootstrap/Button'; 
import Table from 'react-bootstrap/Table'; 
import { Container } from 'react-bootstrap';
import PageLoading from '../layout/PageLoading'; 
import ResetPassword from './ResetPassword';


class Admin extends Component{
    state = { 
        showReset: false,
        selectedUser: ''
     }
    componentDidMount(){
        setUserInfo();
    }
    
    viewUsersJobs = (e) => {
        selectUser(e.target.id); 
        this.props.history.push('/ViewEdit')
    }

    resetCancel = () => {
        this.setState({
            showReset: false,
            selectedUser:''
        })
    }

    resetPassword = (e) => {
        console.log(e.target.id); 
        this.setState({ 
            showReset: true,
            selectedUser: e.target.id
        })
    }

    makeBody(userInfo){
        let body = userInfo.map(each => {
            return(
                <tr key={each.email}>
                    <td>{each.email}</td>
                    <td>{each.last_seen}</td>
                    <td>
                        <Button onClick={this.viewUsersJobs} 
                        size='sm'
                        id={each.email}
                        >
                            View Jobs
                        </Button>
                    </td>
                    <td>
                        <Button onClick={this.resetPassword}
                        size='sm'
                        id={each.email}
                        >
                            Reset Password
                        </Button>

                    </td>
                </tr>
            )
        })
        
        return body; 
    }
    render(){
        let hasInfo = !isEmpty(this.props.userInfo);
        if (hasInfo){
            let body = this.makeBody(this.props.userInfo); 
            return (
                <Container>
                <Table striped bordered hover responsive size='sm' style={{textAlign:'center', marginBottom: '110px'}}>
                    <thead>
                        <tr>
                            <th>Registered Users</th>
                            <th>Last Seen</th>
                            <th>View Jobs</th>
                            <th>Reset Password</th>
                        </tr>
                    </thead>
                    <tbody>
                        {body}
                    </tbody>
                </Table>
                <ResetPassword
                    show = {this.state.showReset}
                    cancel = {this.resetCancel}
                    selectedUser = {this.state.selectedUser}
                />
                </Container>
            )
        }
        else return <PageLoading/>
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    userInfo: state.admin.userInfo
})

export default connect(mapStateToProps)(Admin); 