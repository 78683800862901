import React, { Component } from 'react'; 
import { connect } from 'react-redux'; 
import { isEmpty } from '../../functions/helperFunctions'; 
import Table from 'react-bootstrap/Table';
import { getRandomNumber } from '../../functions/helperFunctions';  


class FileList extends Component{
    render(){      
        let hasFiles = !isEmpty(this.props.files);
        let fileList = <tr><td></td></tr>; 
        if(hasFiles){
            fileList = Object.keys(this.props.files).map(each => {
                let filename = each;
                return(
                    <tr key= {getRandomNumber()}>
                        <td>{filename}</td>
                    </tr>);
            })
        }
        return(
	    <div  style={this.props.style}>
            <Table responsive>
                <tbody>
                    {fileList}
                </tbody>
            </Table>
            </div>
        )
    }
}

const mapStateToProps = state => ({
                                    jobName:state.createJob.jobName,
                                    files:state.createJob.files,
                                    isLoading:state.createJob.isLoading
                                })

export default connect(mapStateToProps)(FileList); 
