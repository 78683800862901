import React, { Component } from 'react'; 
import { css } from '@emotion/core'; 
import RingLoader from 'react-spinners/RingLoader'; 


const override = css`
    display:block;
    margin: 0 auto; 
    border-color: black; 
    text-align:center;
    `

class PageLoading extends Component {
    render(){
        let size = 400
        let padding = '10%'; 
        if (this.props.tiny) {
            size = 100;
            padding = '0%'; 
        }
        let loading = this.props.loading;
        let spinner = (
            <div style={{padding:padding}}>
                <RingLoader
                    css={override}
                    size =  {size}
                    loading={loading}
                    />
            </div>
        )
        return <div >{spinner}</div>
            

        }
    
}

export default PageLoading; 