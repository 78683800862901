import React, { Component } from 'react'; 
import Table from 'react-bootstrap/Table';
import { connect } from 'react-redux';
import TextEditor from './TextEditor'; 
import { isEmpty, shortenString } from '../../functions/helperFunctions';
import PageLoading from '../layout/PageLoading'; 
import SaveTranscript from './SaveTranscript';
import { Prompt } from 'react-router'; 
import Popup from '../layout/Popup'; 
import { clearTranscript  } from '../../actions/textActions'; 


class TextEdit extends Component{

  state = { 
    showModal: false, 
    nextMove:null,
    shouldLeave:false,
    firstVisit:true
  } 

  componentDidUpdate = (e) => {
    let firstVisit = this.state.firstVisit;
    if(firstVisit){
      this.setState({firstVisit:false})
    }
    
    let canLeave = this.props.text.isEdited ? true : undefined; 
    window.onbeforeunload = () => canLeave; 
  }

  goto = () => this.props.history.push(this.state.nextMove); 

  confirmNavigation = () => {
    this.setState({ showModal: false, shouldLeave: true }); 
    clearTranscript(); 
  }

  cancelNavigation = () => {
    this.setState({ showModal: false, shouldLeave: false }); 
  }
    
  handleNav = (e) => {
    if(!this.state.shouldLeave){
      this.setState({
        showModal: true,
        nextMove: e.pathname
      })
      return false;
    }
    return true;  
  }


    render(){
      let hasLines = !isEmpty(this.props.lines); 
      // handle page refresh 
      if(isEmpty(this.props.jobs.jobs)){
        this.props.history.push('/ViewEdit')
      }
      if(this.state.shouldLeave) this.goto(); 
      let tableBody = [];  
      if(hasLines){
        let i = 0; 
        this.props.lines.forEach(element => {
          let key = Object.keys(element)[0];
          let times = key.split(';'); 
          let start = times[0].split('.')[0]
          let stop = times[1].split('.')[0]
          const { sentence, speaker, avg_confidence } = element[key][0]; 
          let isNum = !isNaN(parseFloat(avg_confidence));
          let confidence = isNum ? Math.round((parseFloat(avg_confidence*100))) + "%" : avg_confidence; 
          let tRow = (
            <tr key = {key}>
              <td>{start}</td>
              <td>{stop}</td>
              <td>{speaker}</td>
              <td>{confidence}</td>
              <td>
                <TextEditor key={key} id={key} text={sentence} index={i++}/>
              </td>
            </tr>
          );
          tableBody.push(tRow); 
        });
      }

      let displayTable = (
        <div >
          <h4>
            {shortenString(this.props.text.jobName)}
          </h4>
        <Table striped bordered responsive >
          
          <thead>
            <tr>
              <th>Start</th>
              <th>Stop</th>
              <th>Speaker</th>
              <th>Confidence</th>
              <th>Content</th>
            </tr>
          </thead>
          <tbody>
          {tableBody}
          </tbody>  
        </Table>
        <SaveTranscript/>
        </div>
      )

      let display = hasLines ? displayTable : ""; 
        return(
            <>
            <Prompt when={this.props.text.isEdited} message={this.handleNav} />
            {display}
            <PageLoading loading={!hasLines}/>
            <Popup
              show={this.state.showModal}
              closemodal={this.confirmNavigation}
              cancel={this.cancelNavigation} 
              header={"Warning: "}
              body={"Leaving now will discard all unsaved changes."}
              />
            </>
        );
    }
}

const mapStateToProps = state => ({
    lines: state.text.text, 
    text: state.text,
    jobs: state.jobs
});

export default connect(mapStateToProps)(TextEdit)