import React, { Component } from 'react'; 
import { connect } from 'react-redux';
import Popup from '../layout/Popup';
import { clearError } from '../../actions/errorActions'; 

class DisplayError extends Component{

    close = () => {
        clearError(); 
        let pathname = window.location.pathname;
        if(pathname === '/Edit'){
           window.location.href = '/viewEdit';
           console.log('trying to push'); 
        }
    }

    render(){
        return(
            <Popup
                header='ERROR'
                body = {this.props.errorMSG}
                show = {this.props.isError}
                closemodal = {this.close}
                />
        ); 
    }
}


const mapStateToProps = state => ({
    isError:state.error.isError,
    errorMSG: state.error.errorMSG
})

export default connect(mapStateToProps)(DisplayError); 