import React, { Component } from 'react'; 
import { Editor, EditorState, ContentState, convertToRaw } from 'draft-js';
import Container from 'react-bootstrap/Container'; 
import { connect } from 'react-redux';
import { editSentence } from '../../actions/textActions';
import { isEmpty } from '../../functions/helperFunctions';


class TextEditor extends Component{
    constructor(props){
        super(props)
        this.state = {
            editorState: EditorState.createWithContent(ContentState.createFromText(this.props.text)),
            raw:{}
        };
        
        this.onChange = (editorState) => {
            const contentState = editorState.getCurrentContent(); 
            this.setState({ 
                editorState:editorState,
                raw: convertToRaw(contentState)
            });
            this.onSave();             
        }
    }

    contentToText = () => {
        if(isEmpty(this.state.raw)) return this.props.text; 
        let values = Object.values(this.state.raw.blocks);
        let text = ""; 
        for(let i = 0; i < values.length; i++){
            let line = isEmpty(values[i].text) ? '\n': values[i].text ;
            let nextLine = (i > 0) ? '\n'+line : line; 
            text = text+ nextLine; 
        }
        return text; 
    }
    onSave = () => {
        let text = this.contentToText();
        let edit = {
            key:this.props.id,
            newSentence:text, 
            index:this.props.index
        }
        editSentence(edit); 
    }


    render(){
        return(
            <Container>
                <Editor 
                    editorState={this.state.editorState} 
                    onChange = {this.onChange}
                    spellCheck={true}
                /> 
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    jobs: state.jobs.jobs
});

export default connect(mapStateToProps)(TextEditor)