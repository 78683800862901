import React, { Component } from 'react'; 
import Container from 'react-bootstrap/Container'; 
import { connect } from 'react-redux';
import { isEmpty } from '../../functions/helperFunctions';
import { setJobs, clearJobs } from '../../actions/jobsActions';
import Popup from '../layout/Popup'; 
import JobsTable from './JobsTable';
import SubJobsTable from './SubJobsTable'; 
import { setTranscript, clearTranscript } from '../../actions/textActions';
import PageLoading  from '../layout/PageLoading';  

class ViewEdit extends Component{
    state = { 
        isShowing : false,
        modalIndex: -1,
        isSet: false
     }
     
    componentDidMount(){
        let isSet = this.state.isSet;
        if(this.props.isAdmin)
            clearJobs(); 

        if(!this.props.isError && !isSet)
            setJobs(this.props); 
            this.setState({
                isSet: true
            })
    }

    handleClose = () => {
        this.setState({ isShowing: false });
        setJobs(this.props);      
    }
    
    viewSubJobs = (e) => {
        this.setState({
            isShowing: true,
            modalIndex : e.target.id
        })
    }

    editTranscript = (e) => {
        clearTranscript(); 
        setTranscript(e.target.id); 
        this.props.history.push('/Edit'); 
        
    }

    render(){
        let selectedUser = !isEmpty(this.props.selectedUser) ? this.props.selectedUser : this.props.email; 
        let isAdmin = this.props.isAdmin; 
        let adminMessage = isAdmin ? "Viewing: "+ selectedUser: ""; 
        let hasJobs  = !isEmpty(this.props.jobs); 
        let myModal = ''; 
        if(hasJobs && this.state.modalIndex !== -1){
            let mIndex = this.state.modalIndex; 
            let mHeader = this.props.jobs[mIndex].name;
            let subJobs = this.props.jobs[mIndex].sub_jobs; 

            myModal = (
                <Popup 
                    show={this.state.isShowing}
                    onClick={this.handleClose}
                    header={mHeader}
                    body={<SubJobsTable subJobs={subJobs}/>}
                />
            )
        }

        let display =
            <div style={{textAlign:'center'}}>
                <h6>Getting Transcriptions For: {selectedUser}</h6>
                <PageLoading/>;        
            </div>
        if(hasJobs){
            display= (
                <div>
                    {adminMessage}
                    <JobsTable
                        props={this.props}
                        jobs={this.props.jobs}
                        viewSubJobs={this.viewSubJobs}
                        editTranscript={this.editTranscript}
                    />
                </div>
            );
        }
        return(
                <Container>
                    {myModal} 
                    {display}
                </Container>
        ) 
    }
}

const mapStateToProps = state => ({
    jobs: state.jobs.jobs,
    isError: state.error.isError,
    selectedUser:state.admin.selectedUser, 
    isAdmin:state.auth.user.is_admin,
    email:state.auth.user.email
});

export default connect(mapStateToProps)(ViewEdit);