

import { SET_CURRENT_USER, LOG_OUT } from '../actions/types';
import { isEmpty } from '../functions/helperFunctions';

const initialState = {
    isAuthenticated: false, 
    user: "", 
};

export default function(state = initialState, action){
    switch(action.type){
        case SET_CURRENT_USER:
            return{
                ...state,
                isAuthenticated:!isEmpty(action.payload), 
                user:action.payload
            }
        case LOG_OUT: 
            return {
                ...state,
                user:"",
                isAuthenticated: false, 
                
            }
        default:
            return state; 
    }
}

