import React, { Component } from 'react'; 
import  Button  from 'react-bootstrap/Button'; 
import { connect } from 'react-redux';
import { saveChanges, isChanged } from '../../actions/textActions';
import Notify from './Notify'; 



class SaveTranscript extends Component{
    state = { 
        show: false, 
        message:'',
        color:''
    }
    onClick = async () => {
        console.log('isSaving', this.props.text.isSaving); 
        console.log('isChanged', this.props.text.isEdited); 
        let postData = {
            jobName: this.props.text.jobName,
            transcript:{lines: this.props.text.text} 
        }
        let msg, color = ''; 
        let saved = await saveChanges(postData); 
        if(saved){
            isChanged(false); 
            msg = ' SAVED '
            color = 'green'; 
        }
        else{
            color = 'red'; 
            msg = ' Problem Saving Please Try Again Later '; 
        }
        this.setState({
            show:true,
            message:msg,
            color:color
        })
    }

    onClose = () => this.setState({
        show:false,
        message:'',
        color:''
    }); 

    render(){
        let saveAll = (
            <div>

            <Button onClick={this.onClick} disabled={!this.props.text.isEdited}>
                Save Changes
            </Button>
            <Notify 
                    body={this.state.message}
                    show={this.state.show}
                    onClose={this.onClose}
                    color={this.state.color}
                    />
            </div>
        )
        return(<div>{saveAll}</div>); 
    }

}

const mapStateToProps = state => ({
    text: state.text
}); 

export default connect(mapStateToProps)(SaveTranscript); 